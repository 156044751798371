import React from "react";
import {Container} from "@material-ui/core";

export default function Files() {
    return (
        <Container maxWidth="lg">
            <h1>Dateien</h1>
            <hr/>
            <p>
                Hier kannst du in naher Zukunft Dateien hoch- und herunterladen
            </p>
            <p>
                Diese Seite befindet sich im Bau!
            </p>
        </Container>
    );
}