import React, {useEffect, useState} from "react";
import {Link as RouterLink, useHistory, withRouter} from "react-router-dom";
import {AppBar, Avatar, Button, IconButton, Menu, MenuItem, Tab, Tabs, Toolbar, Typography} from "@material-ui/core";
import {Computer, FileCopy, FlashOn, Note} from "@material-ui/icons";
import {useAuth} from "../contexts/AuthContext";

function Navigation(props) {
    const [anchorEl, setAnchorEl] = useState(null);
    const [value, setValue] = useState(false);
    const {currentUser, logout} = useAuth();
    const history = useHistory();

    useEffect(() => {
        if (props.location.pathname === '/') {
            setValue(0);
        } else if (props.location.pathname === '/files') {
            setValue(1);
        } else if (props.location.pathname === '/esbutztmireis') {
            setValue(2);
        } else if (props.location.pathname === '/posts') {
            setValue(3);
        } else {
            setValue(false);
        }
    }, [props.location.pathname]);

    const handleOpenMenu = (e) => {
        setAnchorEl(e.currentTarget);
    }

    const handleMenuProfile = () => {
        handleCloseMenu();
        history.push('/profile');
    }

    const handleMenuLogout = () => {
        logout();
        handleCloseMenu();
    }

    const handleCloseMenu = () => {
        setAnchorEl(null);
    }

    const handleClickTab = (e, newValue) => {
        setValue(newValue);
    };

    return (
        <>
            <AppBar position="static">
                <Toolbar>
                    <Typography variant='h6' color='inherit'>HindermannDB</Typography>
                    <Tabs variant="scrollable" value={value} indicatorColor="secondary"
                          onChange={handleClickTab} style={{flexGrow: 1}}>
                        <Tab disableRipple label='Startseite' icon={<Computer/>} component={RouterLink} to='/'/>
                        <Tab disableRipple label='Dateien' icon={<FileCopy/>} component={RouterLink} to='/files'/>
                        <Tab disableRipple label='Es butzt mir eis!' icon={<FlashOn/>} component={RouterLink}
                             to='/esbutztmireis'/>
                        <Tab disableRipple label='Posts' icon={<Note/>} component={RouterLink} to='/posts'/>
                    </Tabs>
                    {currentUser ?
                        <IconButton color='inherit' onClick={handleOpenMenu} aria-controls='menu'>
                            <Avatar alt={currentUser.name} src={currentUser.photoURL}/>
                        </IconButton>
                        :
                        <Button variant='contained' color='secondary' component={RouterLink}
                                to='/login'>Einloggen</Button>}
                </Toolbar>
            </AppBar>
            {currentUser && <Menu id='menu'
                                  style={{marginTop: 50}}
                                  onClose={handleCloseMenu}
                                  open={Boolean(anchorEl)}
                                  anchorEl={anchorEl}>
                <MenuItem onClick={handleMenuProfile}>Mein Profil</MenuItem>
                <MenuItem onClick={handleMenuLogout}>Ausloggen</MenuItem>
            </Menu>}
        </>
    );
}

export default withRouter(Navigation);