import React from "react";
import {BrowserRouter as Router, Switch, Route} from "react-router-dom";
import Navigation from './components/Navigation';
import AddPost from "./pages/AddPost";
import Home from './pages/Home';
import Login from "./pages/Login";
import Signup from "./pages/Signup";
import EsButztMirEis from "./pages/EsButztMirEis";
import NotFound from "./pages/NotFound";
import Profile from "./pages/Profile";
import Posts from "./pages/Posts";
import ForgotPassword from "./pages/ForgotPassword";
import {MuiThemeProvider} from "@material-ui/core";
import {AuthProvider} from "./contexts/AuthContext";
import theme from "./utils/theme";
import PrivateRoute from "./components/PrivateRoute";
import Files from "./pages/Files";

function App() {
    return (
        <MuiThemeProvider theme={theme}>
            <Router basename={`${process.env.PUBLIC_URL}`}>
                <AuthProvider>
                    <Navigation/>
                    <Switch>
                        <Route path='/' exact component={Home}/>
                        <Route path='/signup' exact component={Signup}/>
                        <Route path='/login' exact component={Login}/>
                        <Route path='/forgot-password' exact component={ForgotPassword}/>
                        <Route path='/files' exact component={Files}/>
                        <Route path='/esbutztmireis' exact component={EsButztMirEis}/>
                        <Route path='/posts' exact component={Posts}/>
                        <PrivateRoute path='/posts/add' exact component={AddPost}/>
                        <PrivateRoute path='/profile' exact component={Profile}/>
                        <PrivateRoute path='/profile/:details' exact component={Profile}/>
                        <Route path='*' component={NotFound}/>
                    </Switch>
                </AuthProvider>
            </Router>
        </MuiThemeProvider>
    );
}

export default App;
