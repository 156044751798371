import React, {useContext, useEffect, useState} from "react";
import {auth, database} from "../firebase";

const AuthContext = React.createContext();

export function useAuth() {
    return useContext(AuthContext);
}

export function AuthProvider({children}) {
    const [currentUser, setCurrentUser] = useState();
    const [loading, setLoading] = useState(true);

    function signup(email, password) {
        return auth.createUserWithEmailAndPassword(email, password);
    }

    function login(email, password) {
        return auth.signInWithEmailAndPassword(email, password);
    }

    function logout() {
        return auth.signOut();
    }

    function sendEmailVerification() {
        return auth.currentUser.sendEmailVerification();
    }

    useEffect(() => {
        return auth.onAuthStateChanged(user => {
            if (user)
                database.users.doc(user.uid).set({
                    displayName: user.displayName,
                    email: user.email,
                }).then(() => {
                });
            setCurrentUser(user);
            setLoading(false);
        });
    }, []);

    useEffect(() => {

    }, []);

    const value = {
        currentUser,
        signup,
        login,
        logout,
        sendEmailVerification
    }
    return (
        <AuthContext.Provider value={value}>
            {!loading && children}
        </AuthContext.Provider>
    );
}