import React, {useEffect, useState} from "react";
import {Button, Container, Typography} from "@material-ui/core";
import {Link as RouterLink} from "react-router-dom";
import {database} from "../firebase";
import {useAuth} from "../contexts/AuthContext";
import Post from "../components/Post";

export default function Posts() {
    const [posts, setPosts] = useState([]);
    const [loading, setLoading] = useState(true);
    const {currentUser} = useAuth();

    useEffect(() => {
        setLoading(true);
        database.posts.orderBy("createdAt", "desc").onSnapshot(snapshot => {
            setPosts(snapshot.docs.map(database.formatDoc));
            setLoading(false);
        });
    }, []);

    return (
        <Container maxWidth='lg'>
            <h1>Posts</h1>
            <hr/>
            <p>
                Hier kannst du posten.
            </p>
            <Button variant='outlined' color='primary' disabled={!currentUser}
                    component={RouterLink} to='/posts/add'>
                Post erstellen
            </Button>
            {loading &&
            <Typography variant="h2" align="center">
                Loading ...
            </Typography>}
            {posts.length === 0 && !loading &&
            <Typography variant="h2" align="center">
                Keine Daten vorhanden
            </Typography>}
            {!loading && posts.map(post => <Post key={post.id} post={post}/>)}
        </Container>
    );
}