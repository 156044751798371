import React, {useEffect, useState} from "react";
import {makeStyles} from "@material-ui/core";
import {database} from "../firebase";

const useStyles = makeStyles({
    title: {
        display: "inline",
        padding: 0,
        margin: 0,
        fontSize: "2em",
    },
    author: {
        fontSize: "1.25em",
        fontWeight: "normal",
    },
});

export default function Post({post}) {
    const classes = useStyles();
    const date = post.createdAt ? new Date(post.createdAt.seconds * 1000) : new Date();
    const [user, setUser] = useState("");
    useEffect(() => {
        database.users.doc(post.userId).onSnapshot(u => {
            const data = u.data();
            setUser(data.displayName ?? data.email  ?? "anonymous");
        });
    }, [post.userId]);

    return (
        <div>
            <hr/>
            <h3 className={classes.title}>{post.title}</h3> <span className={classes.author}><i>von</i> {user}</span><br/>
            <small>{date.toString()}</small>
            <p dangerouslySetInnerHTML={{__html: post.body}}/>
        </div>
    );
}