import React from "react";
import {Container} from "@material-ui/core";

export default function NotFound() {
    return (
        <Container maxWidth="lg">
            <h1>Seite nicht gefunden</h1>
            <hr/>
            <p>Hier gibt es nichts</p>
        </Container>
    );
}