import React, {useRef, useState} from "react";
import {Button, Container, FormGroup, TextField} from "@material-ui/core";
import {Alert} from "@material-ui/lab";
import {useAuth} from "../contexts/AuthContext";

export default function ForgotPassword() {
    const emailRef = useRef();
    const {resetPassword} = useAuth();
    const [error, setError] = useState('');
    const [loading, setLoading] = useState(false);

    async function handleSubmit(e) {
        e.preventDefault();
        try {
            setError('');
            setLoading(true);
            await resetPassword();
        } catch {
            setError('Ein Fehler ist aufgetreten');
        }
        setLoading(false);
    }

    return (
        <Container maxWidth='lg'>
            <h1>Passwort vergessen</h1>
            <hr/>
            {error && <Alert severity="error">{error}</Alert>}
            <form onSubmit={handleSubmit}>
                <FormGroup row>
                    <TextField id="loginEmail" type="email" label="E-Mail"
                               ref={emailRef}
                               required/>
                </FormGroup>
                <Button variant="contained" color="primary" type="submit" disabled={loading}>
                    Registrieren
                </Button>
            </form>
        </Container>
    );
}