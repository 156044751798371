import React from "react";
import {Container} from "@material-ui/core";
import {StyledFirebaseAuth} from "react-firebaseui";
import {auth} from "../firebase";
import firebase from "firebase/app";
import {useHistory} from "react-router-dom";

export default function Login() {
    auth.useDeviceLanguage();
    const history = useHistory();
    return (
        <Container maxWidth="sm">
            <h1>Einloggen</h1>
            <hr/>
            <StyledFirebaseAuth firebaseAuth={auth} uiConfig={{
                signInFlow: 'popup',
                signInOptions: [
                    firebase.auth.EmailAuthProvider.PROVIDER_ID,
                    firebase.auth.GoogleAuthProvider.PROVIDER_ID,
                    firebase.auth.GithubAuthProvider.PROVIDER_ID,
                ],
                callbacks: {
                    signInSuccessWithAuthResult: async (authResult) => {
                        const userInfo = authResult.additionalUserInfo;
                        if (userInfo.isNewUser && userInfo.providerId === 'password') {
                            try {
                                await authResult.user.sendEmailVerification();
                            } catch {
                                console.log('Failed to send email verification');
                            }
                        }
                        history.push('/');
                        return false;
                    }
                },
            }}/>
        </Container>
    );
}