import React from "react";
import {Avatar, Button, Container, Grid, makeStyles, Typography} from "@material-ui/core";
import {useAuth} from "../contexts/AuthContext";
import {Alert} from "@material-ui/lab";
import {useParams} from "react-router-dom";

const useStyles = makeStyles({
    root: {
        flexGrow: 1,
    },
    avatar: {
        width: 300,
        height: "auto",
        float: "right",
    }
});

export default function Profile() {
    const {currentUser, sendEmailVerification} = useAuth();
    const {details} = useParams();
    const classes = useStyles();

    return (
        <Container maxWidth='lg'>
            <h1>Profil</h1>
            <hr/>
            {currentUser && !currentUser.emailVerified &&
            <Alert severity='warning'>
                Verifiziere deine E-Mail Adresse. Bitte überprüfe deinen Posteingang.
                <Button variant='text' color='inherit' onClick={sendEmailVerification}>Erneut senden</Button>
            </Alert>}
            <p>
                Du befindest dich auf deiner Profil-Seite
            </p>
            {currentUser && <Grid container spacing={2}>
                <Grid item xs={12} lg={8}>
                    <Typography variant="h2">{currentUser.displayName}</Typography>
                    <Typography variant="h4">{currentUser.email}</Typography>
                    <hr/>
                </Grid>
                <Grid item xs={12} lg={4}>
                    <Avatar src={currentUser.photoURL} className={classes.avatar}/>
                    {/*{currentUser.photoURL && <img src={currentUser.photoURL} alt={currentUser.email} className={classes.avatar}/>}*/}
                    {/*{!currentUser.photoURL && <img src={currentUser.photoURL} alt={currentUser.email} className={classes.avatar}/>}*/}
                </Grid>
            </Grid>}
            {details && <div>
                <code style={{float: "none"}}>
                <pre>
            {JSON.stringify(currentUser, null, 2)}
                </pre>
                </code>
            </div>}
        </Container>
    );
}