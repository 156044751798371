import React, {Component} from "react";
import {Typography} from "@material-ui/core";
import {DataGrid} from "@material-ui/data-grid";
import {database} from "../firebase";

const columns = [
    {
        field: 'id',
        headerName: 'ID',
        width: 80,
    },
    {
        field: 'username',
        headerName: 'Person',
        width: 160,
    },
    {
        field: 'date',
        headerName: 'Datum',
        type: 'date',
        width: 120,
    },
    {
        field: 'count',
        headerName: 'Anzahl',
        type: 'number',
        width: 120,
    },
];

// const rows = [
//     {id: 1, lastName: 'Snow', firstName: 'Jon', count: 35},
//     {id: 2, lastName: 'Lannister', firstName: 'Cersei', count: 42},
//     {id: 3, lastName: 'Lannister', firstName: 'Jaime', count: 45},
//     {id: 4, lastName: 'Stark', firstName: 'Arya', count: 16},
//     {id: 5, lastName: 'Targaryen', firstName: 'Daenerys', count: null},
//     {id: 6, lastName: 'Melisandre', firstName: null, count: 150},
//     {id: 7, lastName: 'Clifford', firstName: 'Ferrara', count: 44},
//     {id: 8, lastName: 'Frances', firstName: 'Rossini', count: 36},
//     {id: 9, lastName: 'Roxie', firstName: 'Harvey', count: 65},
// ];

export default class ButzData extends Component {
    constructor(props) {
        super(props);
        this.state = {
            rows: [],
            hasConnection: true
        };
    }

    mapDoc(doc) {
        const data = database.formatDoc(doc);
        return {
            ...data,
            username: data.userId,
        };
    }

    componentDidMount() {
        database.esbutztmireis.orderBy("date", "desc").onSnapshot(snapshot => {
            this.setState({rows: snapshot.docs.map(this.mapDoc)});
        });
    }

    render() {
        const {rows} = this.state;
        return (
            <>
                {rows.length > 0 &&
                <DataGrid rows={rows} columns={columns} pageSize={25} checkboxSelection/>}
                {rows.length === 0 &&
                <Typography variant="h2" align="center">Keine Daten vorhanden</Typography>}
                {this.state.hasConnection !== true &&
                <Typography variant="body2" align="center" color="secondary">
                    Es besteht keine Verbindung zur Datenbank. Ich bitte um Entschuldigung
                </Typography>}
            </>
        );
    }
}