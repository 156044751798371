import firebase from 'firebase/app';
import 'firebase/auth';
import 'firebase/firestore';

const app = firebase.initializeApp({
    // apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
    // authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
    // projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
    // storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
    // messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
    // appId: process.env.REACT_APP_FIREBASE_APP_ID
    apiKey: "AIzaSyAs_LxcBqKIgUOQOhPa9f6OCamki0Skrnk",
    authDomain: "hindermanndb.firebaseapp.com",
    projectId: "hindermanndb",
    storageBucket: "hindermanndb.appspot.com",
    messagingSenderId: "37004556755",
    appId: "1:37004556755:web:37aa6605e9efae2c8f9726"
});

const firestore = app.firestore();
export const database = {
    posts: firestore.collection("posts"),
    users: firestore.collection("users"),
    esbutztmireis: firestore.collection("esbutztmireis"),
    formatDoc: doc => {
        return {id: doc.id, ...doc.data()};
    },
    getCurrentTimeStamp: firebase.firestore.FieldValue.serverTimestamp,
};
export const auth = app.auth();
export default app;