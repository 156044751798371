import React, {useRef, useState} from "react";
import {
    Button,
    Container,
    FormControl, FormGroup,
    FormHelperText,
    Input,
    InputLabel,
} from "@material-ui/core";
import {useAuth} from "../contexts/AuthContext";
import {Alert} from "@material-ui/lab";
import {useHistory} from 'react-router-dom';

export default function Signup() {
    const emailRef = useRef();
    const passwordRef = useRef();
    const passwordConfirmRef = useRef();
    const {signup, currentUser} = useAuth();
    const [error, setError] = useState('');
    const [loading, setLoading] = useState(false);
    const history = useHistory();

    async function handleSubmit(e) {
        e.preventDefault();

        if (passwordRef.current.value !== passwordConfirmRef.current.value) {
            return setError('Passwörter stimmen nicht überein');
        }

        try {
            setError('');
            setLoading(true);
            await signup(emailRef.current.value, passwordRef.current.value);
            history.push('/');
        } catch {
            setError('Fehler beim Erstellen eines Benutzers');
        }
        setLoading(false);
    }

    return (
        <Container maxWidth="sm">
            <h1>Registrieren</h1>
            <hr/>
            {currentUser && currentUser.email}
            {error && <Alert severity="error">{error}</Alert>}
            <form onSubmit={handleSubmit}>
                <FormGroup row>
                    <FormControl>
                        <InputLabel htmlFor="login-email">E-Mail Adresse</InputLabel>
                        <Input id="login-email" type="email" aria-describedby="login-email-text" ref={emailRef}/>
                        <FormHelperText id="login-email-text">We'll never share your email.</FormHelperText>
                    </FormControl>
                </FormGroup>
                <FormGroup row>
                    <FormControl>
                        <InputLabel htmlFor="login-password">Passwort</InputLabel>
                        <Input id="login-password" type="password" ref={passwordRef}/>
                    </FormControl>
                </FormGroup>
                <FormGroup row>
                    <FormControl>
                        <InputLabel htmlFor="login-confirm-password">Passwort wiederholen</InputLabel>
                        <Input id="login-confirm-password" type="password" ref={passwordConfirmRef}/>
                    </FormControl>
                </FormGroup>
                <Button variant="contained" color="primary" type="submit" disabled={loading}>
                    Registrieren
                </Button>
            </form>
        </Container>
    );
}