import React from "react";
import {Badge, Container} from "@material-ui/core";
import ButzData from "../components/ButzData";

export default function EsButztMirEis() {
    return (
        <Container maxWidth="lg">
            <h1>
                <Badge color="secondary" badgeContent="NEW">Es butzt mir eis!</Badge>
            </h1>
            <hr/>
            <div style={{height: 400, width: '100%'}}>
                <ButzData/>
            </div>
        </Container>
    );
}