import React from "react";
import {Container} from "@material-ui/core";

export default function Home() {
    return (
        <Container maxWidth="lg">
            <h1>Startseite</h1>
            <hr/>
            <p>
                Hallo, das ist die HindermannDB!
            </p>
            <p>
                Die aktuelle Version 7 ist jetzt mit ReactJS und Material UI gemacht! (Zuvor mit PHP & Bootstrap)
            </p>
            <h2>Über HindermannDB</h2>
            <p>
                HindermannDB ist eine Website mit Datenbank, die von Nils Hindermann programmiert und weiterentwickelt
                wird.
            </p>
        </Container>
    );
}