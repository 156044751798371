import React, {useState} from "react";
import {Link, useHistory} from "react-router-dom";
import {Button, Container, FormGroup, makeStyles, TextField} from "@material-ui/core";
import {database} from "../firebase";
import {useAuth} from "../contexts/AuthContext";

const useStyles = makeStyles(theme => ({
    root: {
        '& .MuiTextField-root': {
            marginBottom: theme.spacing(1),
            width: "100%",
        },
    },
    btnBack: {
        marginLeft: theme.spacing(1),
    },
}));

export default function AddPost() {
    const classes = useStyles();
    const [title, setTitle] = useState("");
    const [body, setBody] = useState("");
    const [errorHtml, setErrorHtml] = useState([]);
    const {currentUser} = useAuth();
    const history = useHistory();

    const onSubmit = event => {
        event.preventDefault();
        database.posts.add({userId: currentUser.uid, title: title, body: body, createdAt: database.getCurrentTimeStamp()})
            .then(() => history.push('/posts'))
            .catch(e => setErrorHtml(e.message));
    }

    return (
        <Container maxWidth="sm">
            <h1>Post erstellen</h1>
            <hr/>
            <form className={classes.root} onSubmit={onSubmit}>
                <FormGroup row>
                    <TextField id="postTitle" name="title" label="Titel" value={title} variant="outlined"
                               onChange={event => setTitle(event.target.value)}/>
                </FormGroup>
                <FormGroup row>
                    <TextField id="postBody" name="body" label="Inhalt" value={body} variant="outlined" multiline rows={10}
                               onChange={event => setBody(event.target.value)}/>
                </FormGroup>
                <FormGroup row>
                    <Button variant="contained" color="primary" type="submit">
                        Post erstellen
                    </Button>
                    <Button variant="outlined" color="secondary" className={classes.btnBack} component={Link} to="/posts">
                        Zurück
                    </Button>
                </FormGroup>
                {errorHtml}
            </form>
        </Container>
    );
}