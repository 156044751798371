import {createMuiTheme} from "@material-ui/core";

const theme = createMuiTheme({
    palette: {
        primary: {
            main: '#003049'
            // main: '#66da66'
        },
        secondary: {
            main: '#d62828'
            // main: '#da66da'
        },
    },
});

export default theme;